:root {
  /* Light Gray Color */
  --light-gray-color-50: #f4f6f8;
  --light-gray-color-100: #f1f5f9;
  --light-gray-color-200: #EFF1F2;
  --light-gray-color-300: #e6e6e6;

  /* Blue Gray Color */
  --gray-blue-color-400: #919eab;
  --gray-blue-color-675: #637381bf;
  --gray-blue-color-600: #637381;
  --gray-blue-color-700: #454f5b;
  --gray-blue-color-800: #212b36;
  --gray-blue-color-900: #161c24;

  /* Sky Color */
  --secondary-color-500: #0ea5e9;

   /* purple Color */
   --primary-color-50: #F6F5FF;
   --primary-color-100: #e0dcfe;
   --primary-color-200: #C0B7FF;
   --primary-color-500: #624bff;
 
   /* Red Color */
   --red-color-200: #ad000033;
   --red-color-500: #D00101;
 
   /* Green Color */
   --green-color-200: #00980633;
   --green-color-500: #006904;
 
   /* Yellow Color */
   --yellow-color-200: #ee8f0133;
   --yellow-color-800: #8A5300;
 
   /* Common Color */
   --alert-color: #DC3545;
   --warn-color: #F59E0B;
   --success-color: #198754;
   --white-color: #fff;
   --black-color: #000000;
 
   /* Black Color Opacity */
   --black-color-opacity-100: rgba(0, 0, 0, 0.1);
   --black-color-opacity-540: rgba(0, 0, 0, 0.54);
 
}
.border-radius-8 {
  border-radius: 8px;
}
.fw-500 {
  font-weight: 500;
}
.fs-16 {
  font-size: 16px;
}
.custom-logo {
  height: 90px; 
  width: 50px; 
}

