.input_head .credit_list {
    font-size: 24px;
    color: var(--gray-blue-color-800);
}
.input_head.cerdit_list {
    margin-bottom: 30px;
}
.bg_lightgray {
    background-color: var(--light-gray-color-100);
}
.light_purple {
    background-color: var(--primary-color-100);
    padding: 4px 12px;
    border-radius: 4px;
}
.credit_points {
    font-size: 20px;
    color: var(--black-color);
}
.credit_progressbar.progress {
    border-radius: 20px;
    height: 23px;
    background-color: var(--primary-color-100);
}
.credit_progressbar .progress-bar {
    border-radius: 20px;
    background-color: var(--primary-color-500);
}
.credit_used span {
    color: var(--gray-blue-color-600);
}
.request_credit button {
    padding: 12px 24px;
}
.creditrequest_modal .modal-header {
    padding: 28px;
}
.creditrequest_modal .modal-body { 
    padding: 0 28px 28px;
}
.creditrequest_modal .modal-title {
    font-size: 24px;
    color: var(--gray-blue-color-800);
}
.creditrequest_modal .btn-close {
    opacity: 0.8;
}
