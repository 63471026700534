.pg-table-scroll {
  // max-height: calc(520px - -10px);
}

.custom-tbl{
table {
  // border: 1px solid $gray-300;
  thead {
    background-color: $gray-100 !important;
    tr {
      th {
        padding: 12px 24px;
        text-transform: capitalize;
        font-size: 15px;
        color: $gray-900;

        .gap-th {
          gap: 5px;

          p {
            white-space: nowrap;
          }
          span {
            gap: 2px;
          }
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 12px 24px;
        border-bottom: 1px solid $gray-300;
        white-space: nowrap;
        // font-size:15px; 


        .active-btn {
          background-color: #19875433;
          color: $green;
          padding: 2px 14px;
          font-size: 13px;
          display: inline-block;

          &:hover {
            background-color: $green;
            color: $white;
            svg {
              fill: $white;
            }
          }
        }

        .in-active-btn {
          background-color: #dc354533;
          color: $red;
          padding: 2px 14px;
          font-size: 13px;
          display: inline-block;

          &:hover {
            background-color: $red;
            color: $white;
            svg {
              fill: $white;
            }
          }
        }

        .placed-btn {
          background-color: $light-primary;
          color: $primary;
          padding: 2px 14px;
          font-size: 13px;
          display: inline-block;

          &:hover {
            background-color: $primary;
            color: $white;
            svg {
              fill: $white;
            }
          }
        }

        .status-dropdown {
          .active-btn {
            background-color: #19875433;
            color: $green;
            padding: 2px 14px;
            font-size: 13px;

            &:hover {
              background-color: $green;
              color: $white;
              svg {
                fill: $white;
              }
            }
          }

          .in-active-btn {
            background-color: #dc354533;
            color: $red;
            padding: 2px 14px;
            font-size: 13px;

            &:hover {
              background-color: $red;
              color: $white;
              svg {
                fill: $white;
              }
            }
          }

          .placed-btn {
            background-color: $light-primary;
            color: $primary;
            padding: 2px 14px;
            font-size: 13px;
            display: inline-block;

            &:hover {
              background-color: $primary;
              color: $white;
              svg {
                fill: $white;
              }
            }
          }

          button {
            &::after {
              content: "";
              display: none;
            }
          }

          .dr-menu {
            transform: translate(0px, 37px);
            padding: 4px 0;
            .dr-menu-item {
              padding: 0 12px;
              font-size: 13px;
            }
          }
        }

        .action-dropdown {
          .action-dr-menu {
            .dr-item {
              gap: 6px;
              font-size: 14px;
              &:focus-visible {
                outline: none;
              }
            }
            .item-orange {
              &:hover,
              &:focus,
              &:active {
                // background-color: $extra-light-orange;
                color: $orange;
                span {
                  svg {
                    fill: $orange !important;
                    path {
                      fill: $orange !important;
                    }
                  }
                }
              }
            }
            .item-green {
              &:hover,
              &:focus,
              &:active {
                // background-color: $extra-light-green;
                color: $green;
                span {
                  svg {
                    fill: $green !important;
                    path {
                      fill: $green !important;
                    }
                  }
                }
              }
            }
            .item-red {
              &:hover,
              &:focus,
              &:active {
                // background-color: $extra-light-red;
                color: $red;
                span {
                  svg {
                    fill: $red !important;
                    path {
                      fill: $red !important;
                    }
                  }
                }
              }
            }
          }
          button {
            &::after {
              content: "";
              display: none !important;
            }
          }
        }

        .no-data {
          font-size: 15px;
          font-weight: 500;
          color: $gray-800;
        }
      }

      &:hover {
        td.sticky-td {
          position: sticky;
          right: 0;
          background-color: $white;
          z-index: 1;
        }
      }
    }
  }

  .cu-tbody {
    tr:last-child {
      td {
        border-bottom: none !important;
      }
    }
  }

  tfoot {
    background-color: $gray-100 !important;
    tr {
      td {
        padding: 12px 24px;
        p {
          font-weight: 600;
          color: $gray-900;
        }
      }
    }
  }
}

.table > :not(caption) > * > * {
  padding: 0;
  box-shadow: none;
  border-bottom: 0;
  background-color: transparent;
}

.table-tabs {
  .table-tabs-items {
    gap: 12px;
    li {
      button {
        // background-color: $extra-light-primary;
        // background-color: $gray-100;
        // border: none;
        // border-radius: 6px;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 2px solid transparent;
        border-radius: 0;
        // color: $primary !important;
        color: $gray-800 !important;
        outline: none !important;
        font-size: 15px;
        font-weight: 500;
      }

      button:active,
      button:focus,
      button:focus-visible,
      button:hover,
      .nav-link.active,
      .nav-link.focus,
      .nav-link.focus-visible,
      .nav-link.hover {
        // background-color: $primary !important;
        color: $primary !important;
        border-bottom: 2px solid $primary;

        svg {
          fill: $primary;
        }
      }
    }
  }
}
}

.table-tabs {
  .table-tabs-items {
    gap: 12px;
    li {
      button {
        // background-color: $extra-light-primary;
        // background-color: $gray-100;
        // border: none;
        // border-radius: 6px;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 2px solid transparent;
        border-radius: 0;
        // color: $primary !important;
        color: $gray-800 !important;
        outline: none !important;
        font-size: 15px;
        font-weight: 500;
      }

      button:active,
      button:focus,
      button:focus-visible,
      button:hover,
      .nav-link.active,
      .nav-link.focus,
      .nav-link.focus-visible,
      .nav-link.hover {
        // background-color: $primary !important;
        color: $primary !important;
        border-bottom: 2px solid $primary;

        svg {
          fill: $primary;
        }
      }
    }
  }
}
