/*
=========================================================
* Dash UI - Bootstrap 5 Admin & Dashboard Theme
=========================================================
* Product Page: https://codescandy.com/dashui/index.html
* Copyright 2023 Codescandy (https://codescandy.com/)
* Designed and coded by https://codescandy.com
========================================================= */

// Inter Fonts
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

// Font Awesome
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css");

// Material Design Icons
@import url("https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/7.2.96/css/materialdesignicons.min.css");

// Feathers CSS for Before Icon
// @import url("./fonts/feather-icons/feather.css");

// Bootstrap functions
@import "~bootstrap/scss/functions";

// User Variables
@import "user-variables";

// theme variables
@import "theme/variables";

//utilities
@import "theme/utilities";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Theme
@import "theme/theme";

// User
@import "user";

//dashboard
@import "./pages/dashboard.scss";

@import "./pages/credits-log.scss";

.heading-permalink{
    color: transparent;
    margin-left: -2rem;
    &:hover{
        color: transparent;


    }



&::before {
    color: rgba(82,95,127,0);
    content: "#";



}
&:hover:before{
    color: $primary;



}

}
