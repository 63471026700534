.navLink {
  color: black !important;
  background-color: white;
}

.navLinkActive {
  background-color: #624bff !important;
  color: white !important;
  border-bottom: 3px solid #624bff !important;
}
.tablecontainer {
  margin: 15px;
  padding: 30px;
  background: white;
}
.searchbar {
  background: #f1f5f9;
  border: none;
  padding: 7px;
  border-radius: 5px;
}
.input_btn {
  margin-bottom: 70px;
}
.data_list {
  overflow-x: auto;
}
.data_list thead th:first-child {
  border-radius: 10px 0 0;
}
.data_list thead th:last-child {
  border-radius: 0 10px 0 0;
}
.data_list thead th {
  color: #454f5b;
  font-size: 16px;
}
.data_list tbody td {
  color: #454f5b;
  font-size: 15px;
}
.list_pagination li.prev_btn a,
.list_pagination li.next_btn a {
  color: var(--black-color);
  font-size: 12px;
  font-weight: 500;
}
.list_pagination li.prev_btn,
.list_pagination li.next_btn {
  margin: 4px 0 !important;
}
.list_pagination li {
  margin: 5px;
}
.list_pagination .active a {
  background: #637381;
  border: none;
  color: white;
  border-radius: 8px;
}
.list_pagination li a {
  background-color: #eff1f2;
  border-radius: 8px;
  border: none;
  color: black;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}

li.next_btn.page-item.disabled span {
  border: none;
  background: transparent;
}

li.prev_btn.page-item.disabled span {
  border: none;
  background: transparent;
}
.data_list tbody .data_row:hover td {
  background: #f4f6f8;
}
.list_pagination {
  padding-top: 80px;
}
.bg-lightgray .modal-content {
  background-color: #f1f5f9;
  padding: 40px;
  height: 100%;
  width: 100%;
  position:relative;
  right: 0px;
  overflow: hidden;
}
#page-content {
  background-color: #f1f5f9;
}
.customer_data .input_head {
  margin-bottom: 30px;
}
.customer_data .input_head input {
  outline: none;
}
.customer_data .input_head h5 {
  font-size: 22px;
  /* color: #212b36; */
  color: var(--gray-blue-color-800);

}
.tablist_data li button {
  border: none !important;
}
.tablist_data li button.active {
  background-color: #f6f5ff !important;
  color: #624bff !important;
  border-radius: 8px !important;
}
.tablist_data li button {
  font-size: 16px;
  font-weight: 500;
  /* color: #212b36 !important; */
  color: var(--gray-blue-color-800) !important;
  padding: 12px 24px;
}
.customer_details {
  padding: 30px;
  background: white;
}
@media (max-width: 1200px) and (max-height: 733px) {
  .customer_details {
    height: 500px;
    overflow-y: auto;
  }
}
.detail_person .user_name {
  width: 150px;
  /* font-size: 14px;
  color: #637381; */
}
.detail_person .part_name {
  width: 20px;
  /* color: #454f5b; */
  color: var(--gray-blue-color-700);
}
.detail_person .detail_name {
  color: var(--gray-blue-color-700);
}
.separtor.line {
  height: 2px;
  width: 100%;
  background: #e6e6e6;
}
.separtor.line_small {
  height: 2px;
  width: 37%;
  background: #e6e6e6;
}
.customer_details h5 {
  font-size: 22px;
  /* font-weight: 500; */
}
.modal-fullscreen .modal-title {
  font-size: 28px;
  color: #624bff;
}
.modal-fullscreen .modal-header button {
  opacity: 0.7;
}
.table-row {
    cursor: pointer;
}
.status_update {
  width: 105px;
}
.floating-label-content {
  margin-bottom: 32px;
}
.floating-label {
  font-size: 15px;
  pointer-events: none;
  left: 22px;
  top: 12px;
  padding: 0 5px;
  transition:
    top 0.3s ease,
    font-size 0.3s ease,
    color 0.3s ease;

}
.floating-input {
  transition: border-color 0.3s ease;
  height: 48px;
  padding: 0 20px;
  color: var(--gray-blue-color-800);
  border: 1px solid var(--gray-blue-color-600);
  border-radius: 4px;
}
.floating-input:focus,
.floating-label:focus {
  outline: none;
  border-color: var(--primary-color-500);
}
.floating-input:focus ~ .floating-label {
  top: -8px;
  font-size: 13px;
}
.floating-label-content input:focus + label {
  color: var(--primary-color-500) !important;
}
.floating-label-content input:not(:placeholder-shown) + label {
  top: -9px;
  font-size: 12px;
  left: 25px;
  color: var(--gray-blue-color-900);
}
.floating-label-content .required_alert {
  color: var(--alert-color);
}
.floating-input:focus {
  background-color: white;
}
.top_title .subhead_title {
  font-size: 38px;
  font-weight: 600;
}
.top_title {
  padding: 0px 55px;
}
.rupee-symbol {
  left: 10px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.floating-input {
  padding-left: 20px; /* Adjust padding to accommodate the rupee symbol */
}
.logdata_list thead th {
  border-bottom: none;
  background: #eff1f2;
}
.logdata_list thead th:first-child,
.logdata_list tfoot th:first-child {
  border-radius: 10px 0 0 10px;
  border-left: 0 !important;
}
.logdata_list thead th:last-child,
.logdata_list tfoot th:last-child {
  border-radius: 0 10px 10px 0;
}
.table tbody tr td span {
  /* color: #454f5b; */
  font-size: 12px;
}
.table thead tr th span,
.table thead tr th p,
.table tfoot tr th span {
  color: #454f5b;
  font-size: 16px;
  font-weight: 500;
}
.logdata_list tbody td,
.logdata_list thead th,
.logdata_list tfoot th {
  border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.logdata_list tbody td:first-child {
  border-left: 0 !important;
}
.logdata_list tfoot th {
  border-bottom: none;
  background: #eff1f2;
}
.tbody_scrolly {
  max-height: 470px;
  overflow-y: auto;
}
.tbody_scrolly .tbody_scroll thead th{
  position: sticky;
  top: 0;
  z-index: 1;
}
.tbody_scrolly .tbody_scroll tfoot th {
  position: sticky;
  bottom: 0;
  z-index: 1;
}
.modal-fullscreen .modal-content {
  height: inherit !important;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background: #D9D9D9;
  -webkit-border-radius: 1ex;
}
.nav-link:focus-visible {
  box-shadow: none;
}
.rupee-input-container {
  position: relative;
  display: flex;
  align-items: center;
}
.rupee-symbol {
  position: absolute;
  left: 8px; 
  color: #495057; 
}
.form-input {
  padding-left: 24px; 
}
.daterangepicker button {
  width: 100%; 
  height: auto;  
  z-index: 1000;  
  position: relative; 
}






